<template>
	<div class="recommended-article">
		<base-link to="article" :params="{alias: article.alias}">
			<div class="image-wrapper">
				<cms-image
                class="image"
                :src="article.thumbnail"
                :base="{ width: 690, height: 250 }"
                :lg="{ width: 690, height: 350 }"/>
			</div>
            <div class="description-wrapper">
                <base-font class="title" variant="small-header">{{ article.title }}</base-font>
                <span class="article-description" v-html="article.description"></span>
            </div>
		</base-link>
	</div>
</template>

<script>
export default {
	props: {
		article: Object
	}
}
</script>

<style scoped lang="scss">
.recommended-article {
    border: 1px solid $secondary;
	flex: 0 0 100%;
	box-shadow: $base-shadow;

	@include media-breakpoint-up(md) {
		flex: 0 0 48%;

		&:nth-of-type(5) {
			flex: auto;
		}
	}

	@include media-breakpoint-up(xl) {
		flex: auto;
		max-width: 250px;

		&:nth-of-type(5) {
			flex: auto;
		}
		&:nth-of-type(4) {
			flex: auto;
		}
	}

	::v-deep a {
		text-decoration: none;
	}

	.image-wrapper {
		position: relative;
		max-width: 100%;
	}

	::v-deep .base-image {
		width: 100%;
		height: 100%;
	}
    .description-wrapper {
        padding: 2rem;
        ::v-deep .title {
            margin-bottom: 2rem;
            color: $secondary;
        }
        .article-description {
            color: initial;
            font-weight: 400;
            font-size: 1.4rem;
            letter-spacing: 0.04em;
            text-transform: initial;
        }
    }
}
</style>
