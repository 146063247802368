<template>
    <div class="author-wrapper">
        <div class="author-avatar">
            <cms-image
                class="author-image"
                lazy
                :src="authorData.avatar"
                :base="{width: 100, height: 100}"
                :lg="{width: 200, height: 200}"
            />
        </div>
        <div class="author-description">
            <base-font variant="medium-heading">{{authorData.name}} {{authorData.surname}}</base-font>
            <base-font variant="paragraph"> {{authorData.description }}</base-font>
        </div>
    </div>
</template>
<script>
export default {
	props: {
		authorData: Object
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	}
}
</script>
<style lang="scss" scoped>
.author {
    &-wrapper {
        flex-direction: column;
        align-items: center;
        margin: 3rem auto;
        border: 1px solid $secondary;
        display: flex;
        border-radius: 10px;
        @include media-breakpoint-up(sm) {
            flex-direction: row;
            align-items: initial;
            border-radius: 10px 0 0 10px;
        }
        overflow: hidden;
    }
    &-description {
        display: flex;
        background: $gray-300;
        width: 100%;
        padding: 3rem;
        //padding: 1.5rem;
        flex-direction: column;
        justify-content: center;
        @include media-breakpoint-up(sm) {

        }
    }
    &-avatar {
        width: 100%;
        display: flex;
        justify-content: center;
        @include media-breakpoint-up(sm) {
            align-items: center;
            width: initial;
        }
        background: $secondary;
        padding: 2rem;
    }
}
</style>
