<template>
	<div class="readmore" :class="{active: isActive}" v-if="recommendedArticle">
		<div class="top-wrapper">
			<base-font variant="small-heading" marginReset>{{langs.read_more}}</base-font>
			<button-close @close.stop="close"/>
		</div>
		<base-link to="article" :params="{alias: recommendedArticle.alias}">
			<base-font variant="small-header" color="secondary" class="readmore-title">{{recommendedArticle.title}}</base-font>
			<span class="readmore-description" v-html="recommendedArticle.description"></span>
		</base-link>
	</div>
</template>
<script>
import { debounce } from 'utils/debounce'
import ButtonClose from '~/website/front/components/molecules/ButtonClose/ButtonClose.vue'

export default {
	components: { ButtonClose },
	props: {
		height: Number
	},
	computed: {
		recommendedArticle () {
			return this.$app.page.value.recommendedArticles.slice(0, 1)[0]
		},
		langs () {
			return this.$app.translator.get('articles')
		}
	},
	data: () => ({
		isActive: false,
		openDebounce: null,
		wasOpen: false
	}),
	methods: {
		close () {
			this.isActive = false
			this.wasOpen = true
		},
		open () {
			if (!this.wasOpen && (window.scrollY > (this.height / 2))) {
				this.isActive = true
			} else {
				this.isActive = false
			}
		}
	},
	mounted () {
		this.openDebounce = debounce(this.open, 100)
		window.addEventListener('scroll', this.openDebounce)
	},
	beforeDestroy () {
		window.removeEventListener('scroll', this.openDebounce)
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	}

}
</script>
<style lang="scss" scoped>
 .readmore {
    display: none;
	@include media-breakpoint-up(xl) {
        display: block;
    }
	opacity: 0;
	pointer-events: none;
	width: 40rem;
	padding: 2rem;
	background: $white;
	box-shadow: 0 3px 6px rgba($black, .12);
	position: fixed;
	bottom: 12rem;
	right: 3rem;
	z-index: 5;
	text-transform: none;

	&.active {
		opacity: 1;
		pointer-events: all;
	}
	&-description {
		color: $primary;
		font-size: 14px;
	}
	.top-wrapper {
		display: flex;
		align-items: center;
        margin-bottom: 1rem;
		.line {
			border-bottom: 1px solid $primary;
			flex: 1;
		}
		.svg-icon {
			height: 17px;
			width: 17px;
		}

        ::v-deep .wrapper {
        width: 55%;
        padding: 0;
        }
	}
    &-description {
        font-weight: 400;
        display: none;
		text-transform: initial;
		line-height: 2.1rem;
        @include media-breakpoint-up(xxl) {
            display: block;
        }
    }
}
</style>
