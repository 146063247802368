import { Seo } from '~/cms/front/core/seo/Seo'

class ArticleData extends Seo {
	get author () {
		const { name, surname } = this.app.page.value.author
		return `${name} ${surname}`
	}

	get schemaJSON () {
		return {
			'@context': 'https://schema.org/',
			'@type': 'Article',
			headline: this.app.page.value.title,
			datePublished: this.app.page.value.datePublished,
			image: `${this.app.settings.env.origin}/api/images/${this.app.page.value.thumbnail.path}`,
			author: {
				'@type': 'Person',
				name: this.author,
				url: `${this.app.settings.env.origin}/api/images/${this.app.page.value.author.avatar}`
			},
			publisher: {
				'@type': 'Organization',
				name: this.app.settings.general.contact.pageName,
				logo: {
					'@type': 'ImageObject',
					url: `${this.app.settings.env.origin}/api/images/nimphy_logo.png`,
					width: 210,
					height: 60
				}
			}
		}
	}
}

export { ArticleData }
