<template>
	<article class="article">
		<base-container>
			<div>
				<div>
					<div class="article-body" ref="sectionContent">
						<base-font class="blog-header" variant="blog-heading">{{article.title}}</base-font>
						<base-font class="article-date" color="primary">{{article.datePublished | date}}</base-font>
                        <cms-image
                            v-if="article.thumbnail"
                            class="article-image"
                            lazy
                            :src="article.thumbnail"
                            :base="{width: 500, height: 200}"
                            :md="{width: 830, height: 200}"
                            :xxl="{width: 1120, height: 200}"
                        />
						<div class="article-introduction" v-html="article.introduction"></div>
						<cms-multi-section class="article-text" value="content" />
						<div>
							</div>
							<article-author :authorData="article.author"/>
							<div class="back-button-wrapper">
								<base-button class="back-button" to="article-list" type="secondary">{{langs.back_button}}</base-button>
							</div>
						</div>
					</div>
				</div>
		</base-container>
		<recommended-articles-wrapper />
		<article-readmore :height="articleHeight"/>
	</article>
</template>

<script>
import ArticleReadmore from '~/blog/front/components/ArticleReadmore/ArticleReadmore.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import RecommendedArticlesWrapper from '~/blog/front/components/RecommendedArticles/RecommendedArticlesWrapper'
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import ArticleAuthor from '~/blog/front/components/ArticleAuthor/ArticleAuthor.vue'

export default {
	props: {
		prefix: String
	},
	components: {
		RecommendedArticlesWrapper,
		CmsMultiSection,
		ArticleReadmore,
		ArticleAuthor
	},
	data: () => ({
		articleHeight: 0,
		progress: 0
	}),
	computed: {
		article () {
			return this.$app.page.value
		},
		langs () {
			return this.$app.translator.get('articles')
		}
	},
	async mounted () {
		this.articleHeight = this.$refs.sectionContent.offsetHeight
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	},
	mixins: [
		pageMixin
	]
}
</script>
<style lang="scss" scoped>
.article{
	padding: 10rem 0 4rem 0;
	@include media-breakpoint-up(xl) {
		padding: 16rem 0 8rem 0;
	}
	.back-button {
		justify-content: center;
        &-wrapper {
            display: flex;
        }
	}
    &-image {
        display: flex;
        justify-content: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
        ::v-deep .base-image {
            width: 100%;
        }
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }
	&-text {
		::v-deep p {
			line-height: 2.3rem;
		}
	}
    ::v-deep &-date {
        margin-bottom: 2rem;
    }
}
.article-body {
	margin: 0 auto;
	@include media-breakpoint-up(xl){
		max-width: 80%;
	}

	@include media-breakpoint-up(xxl){
		max-width: 70%;
	}

	::v-deep .blog-header {
		margin-bottom: 1rem;
        text-align: center;
	}
	.article-introduction {
		color: $primary;
		margin-bottom: 1.8rem;
		line-height: 2.3rem;

		@include media-breakpoint-up(xl) {
			margin-bottom: 1.8rem;
            margin-top: 3rem;
		}
		strong {
			font-size: 2.4rem;
		}
	}

	::v-deep .article-date {
		width: 100%;
		position: relative;
		overflow: hidden;
		opacity: .5;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 10rem;
			width: 100%;
			border-bottom: .1rem solid $gray;
		}
	}
}

.timeline {
	display: none;
	position: absolute;
	left: -4rem;
	top: 0;
	background: $gray;
	width: 4px;
	height: 100%;

	@include media-breakpoint-up(lg){
		display: block;
	}

	&-progress {
		width: 100%;
		background: $secondary;
		position: relative;

		&::after{
			content: '';
			position: absolute;
			bottom: 0;
			width: 100%;
			height: .4rem;
			background: rgba($secondary, .6);
		}
	}
}
</style>
