<template>
    <div class="recommended-articles-wrapper" v-if="recommendedArticles.length">
        <base-container>
        <base-font variant="small-heading" color="secondary" weight="bold" class="blog-heading">{{langs.recommended_title}}</base-font>
        <div class="recommended-articles">
        <recommended-article v-for="article in recommendedArticles" :key="article.id" :article="article" />
        </div>
        </base-container>
    </div>
</template>

<script>
import RecommendedArticle from '~/blog/front/components/RecommendedArticles/RecommendedArticle'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'

export default {
	components: {
		RecommendedArticle,
		BaseFont
	},
	computed: {
		recommendedArticles () {
			return this.$app.page.value.recommendedArticles.slice(0, this.$app.page.value.recommendedArticles.length)
		},
		langs () {
			return this.$app.translator.get('articles')
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	}
}
</script>

<style scoped lang="scss">
::v-deep .blog-heading {
    margin-bottom: 2rem;
    @include media-breakpoint-up(sm) {
        margin-bottom: 0;
    }
}
.recommended-articles {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 2rem;
    @include media-breakpoint-up(sm) {
        padding: 4rem 0;
    }
    &-wrapper {
        padding: 0;
        @include media-breakpoint-up(sm) {
            padding: 4rem;
        }
        display: flex;
        flex-direction: column;
        background: $gray-300;
        margin-top: 2rem;
    }
}
</style>
